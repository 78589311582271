<template>
<div>
    <!-- <linkbutton toplabel="Update Student Info" @click="startUpdateStudent()"/> -->
    <pencilbutton @click="startUpdateStudent()"/>
    <!-- MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form id="updatestudentform" ref="updatestudentform" @submit.prevent>
                <label class="titlelabel">Update Student</label>
                <formentry :label="studentfirstNameLabel" isrequired='True' v-model="student.FirstName" />
                <formentry :label="studentlastNameLabel" isrequired='True' v-model="student.LastName"  />
                <formentry :label="studentIDLabel" :isrequired="requireStudentID" v-model="student.StudentID"/>
                <formentry  inputtype="tel"  label="Phone Number" @blur="leavePhone"  v-model="student.PhoneNumber1" />
                <formentry  inputtype="email"  label="Email Address"  v-model="student.EmailAddress" />
                <formentry :label="idLabel"  v-model="student.InternalID"/>
                <formentry :label="fidLabel" :isrequired='requireFID'    v-model="student.fId" v-if="showFID" :placeholder="FIDPlaceholder"/>
                <formentry :label="studentPreferredLabel"  v-model="student.PreferredFirstName"/>

                <div class="entry">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Role</label>
                    </div>
                    <select name="" id="" class='entrySelect' v-model="student.personRoleId" required v-if='allowSelectPersonRole'>
                        <option v-for="role in personRoleList" :key="role.id" :value="role.id" >
                            {{role.name}}
                        </option>
                    </select>
                </div>

                <formentry label="Address 1"  v-model="student.AddressLine1"/>
                <formentry label="Address 2"  v-model="student.AddressLine2"/>
                <formentry label="City"  v-model="student.City"/>
                <div class="entry">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">State</label>
                    </div>
                    <select name="" id="" class='entrySelect' v-model="student.StateID" required>
                        <option v-for="state in states" :key="state.id" :value="state.id" >
                            {{state.name}}
                        </option>
                    </select>
                </div>
                <formentry label="Zip"  v-model="student.ZipCode"/>

                <smallbutton  @click="saveUpdatedStudent()" toplabel="Save" />
                <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Cancel"/>
                <div>
                    <label class="responsemessage">{{responseMessage}}</label>
                </div>
            </form>
            
        </template>
      </actionmodal>
    </div>
</template>

<script>
import datamethods from '../../datamethods.js'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import actionmodal from './actionmodal.vue'
import formentry from '../controls/entry.vue'
import linkbutton from '../controls/linkbutton.vue'
import pencilbutton from '../controls/pencilbutton.vue'
export default {
    name:'updatestudent',
    components:{
        smallbutton,
        actionmodal,
        formentry,
        linkbutton,
        pencilbutton
    },
    props:{
        personId:{
            type:Number,
            required:true
        },
        accountId:{
            type:Number,
            required:true
        }
        
    },
    mixins:[datamethods,format],
    data(){
        return{
            responseMessage:'',

            student:{},

            requireStudentID:window.REQUIRESTUDENTID.toString(),
            studentfirstNameLabel:'Student First Name',
            studentlastNameLabel:'Student Last Name',
            studentPreferredLabel:'Preferred Name (Nickname)',

            states:[],

            fidLabel:'SSN',

            personRoleList:[],
            personRoleId:0
        }
    },
    computed:{
        allowSelectPersonRole(){
            if(window.ALLOW_SELECT_PERSON_ROLE==undefined){return false}
            else{return window.ALLOW_SELECT_PERSON_ROLE}
        },
        studentIDLabel(){
            if(this.requireStudentID){return 'Student ID'}else{return 'Student ID (optional)'}
        },
        requireFID(){
            if(window.REQUIRE_SSN==undefined){return false}
            else{return window.REQUIRE_SSN=="True"}
        },
        showFID(){
            if(window.SHOW_SSN==undefined){return false}
            else{return window.SHOW_SSN=="True"}
        },
        FIDPlaceholder(){
            var placeholder=''
            if(window.SSN_LENGTH==undefined){placeholder='last 4 digits'}
            else if(window.SSN_LENGTH==4){placeholder='last 4 digits'}
            else{placeholder=window.SSN_LENGTH + ' digits'}
            return placeholder
        },
        FIDLength(){
            var l=4
            if(window.SSN_LENGTH!=undefined){l=window.SSN_LENGTH}
            return l
        },
        idLabel(){
            var l = "Permit #"
            if(window.ID_LABEL_OVERRIDE!=undefined){
                if(window.ID_LABEL_OVERRIDE!=''){
                    l=window.ID_LABEL_OVERRIDE
                }
            }
            return l
        },
    },
    methods:{
        isOKFID(fid){
            if(fid==null){return true}
            fid = fid.replaceAll('-','').replaceAll('.','').replaceAll(' ','')
            console.log(fid.length)
            return fid.length==this.FIDLength
        },
        async getstates(){
            this.message = ''
            var response = await this.callAPI(null,'states')    
            if(response.Successful){
                this.states=response.stateList
            }else{
                this.message = response.Message
            }

        },
        async startUpdateStudent(){
            this.getstates()
            this.getRoles()
            this.message = ''
            let req = {
                person:{
                    ID:this.personId
                }
            }

            var response = await this.callAPI(req,'getperson')    
            if(response.Successful){
                console.log(response)
                this.student=response.Person;
                this.student.PhoneNumber1=this.$options.filters.formatPhone(this.student.PhoneNumber1);
                this.student.accountId=this.accountId;
                this.$refs.mymodal.openModal()
            }else{
                this.message = response.Message
            }

        },
     
        async saveUpdatedStudent(){
            if(this.$refs.updatestudentform.checkValidity()==false){return}

                if(!this.isOKFID(this.student.fId)){
                    if(this.FIDLength==4){
                        this.responseMessage="Please enter the last 4 digits of your SSN"
                    }else{
                        this.responseMessage="Please enter a " + this.FIDLength + ' digit SSN'
                    }
                }

            let req = {
                person:this.student,
            }
            var response = await this.callAPI(req,'updatepersoncheckbadge')
            if(response.Successful){
                this.$refs.mymodal.closeModal()
                console.log(response)
                this.$emit('refresh')
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        },
        async getRoles(){
            let req = {
                locationId:-1
            }
            //this.beforeAPI();
            var response = await this.callAPI(req,'rolelist')

            //handle response
            if(response.Successful){
            this.personRoleList = response.PersonRoleList
    
            if(this.personRoleList.length>0){
               //find student and pick it as default
                var studentId=-1
                this.personRoleList.forEach(myFunction2)

                function myFunction2(item, index, arr) {
                    let acct=arr[index]
                    if(acct.name=='Student'){
                        studentId=acct.id
                    }
                }
                //this.personRoleId=studentId 
            }
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
       
    }
}
</script>

<style>
.entryCheckLabel{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
}
.entryCheckLabelbold{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
    font-weight:bold;
}
</style>





























