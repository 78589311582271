<template>
  <div :class="mainedgeClass">
    <div class="personPhoto" v-if="account.photoFileName!=null && showAccountImage">
        <img :src="getImage"  width="75" :class="recordImageClass()" @click="toggleImageSize(image,$event.currentTarget)">
        
        <linkbutton v-if="personImageEnlarged" toplabel="Edit"  @click="$refs.imgedit.openModal()" class="photobutton"/>
        <linkbutton v-if="personImageEnlarged" toplabel="Replace" @click="$refs.imgall.openModal()" class="photobutton"/>
        
    </div>    
    <div class="leftColumn">
            
            <div class="horizFlex">
                <label for="" class="labelBold right10">{{buildResultName}} </label>
                <updatestudent :personId="account.personId" :accountId="account.ID" @refresh="$emit('refresh')" v-if="account.IsActive"/>
                <confirmwithreason :showButton="trueParam" body="Do you want to reactivate this account?" title="Reactivate Account" v-model="deactivateReason" @yes="ReactivateAccount()" v-if="isAdmin && !account.IsActive"/>
               <!--  <linkbutton toplabel="Update Student Info"    @click="startUpdateStudent(account)" v-if="isAdmin"/> -->
            </div>
            
            <label class="label" >{{'#: '  + account.ID}}</label>
            <label class="label" v-if="showIdNumber">{{idLabel  + account.internalId}}</label>
            <label class="label" >{{'Student ID: ' + account.studentId}}</label>
            <label class="label" v-if="showAccountType">{{'Account Type: '  + account.accountTypeName}}</label>
             <label class="label" v-if="account.fId!=null" >{{obscuredFID(account)}}</label>
             <label class="label" v-if="account.productString!=null" >{{'Purchased:' + account.productString}}</label>
             <div class="horizFlex" v-if="showTuitionPaymentType">
                <label class="label"  >{{tuitionPmtType(account)}}</label>
                <update-tuition-type :account="account" @refresh="$emit('refresh')" v-if="isAdmin && account.IsActive"/>  
             </div>
             
             <div class="horizFlex" v-if="showReferredBy">
                <label class="label"  >{{referred(account)}}</label>
                <updatereferredby :account="account" @refresh="$emit('refresh')" v-if="isAdmin && account.IsActive"/>  
             </div>
            <div class="horizFlex" v-if="showMealPlan">
                <label class="label"  >{{mealplan(account)}}</label>
                <updatemealplan :account="account" @refresh="$emit('refresh')" v-if="isAdmin && account.IsActive"/>  
             </div>
            
        </div>
        <div class="centerColumn">
            <label class="wrappedtext">{{account.contactInfoString}}</label>
        </div>
        <div class="rightColumn">
            <label class="labelBold" v-if="showAccountBalance">{{formatBalance(account.currentBalance) }}</label>
            <label :class="dueLabelClass">{{dueText}}: {{account.amountDue | toCurrency}}</label>
            <label class="label" v-if="accountHasDueDate">Due Date: {{account.dueDate | formatDate}}</label>
             <br>
            <linkbutton  :toplabel="actionLabel"  @click="toggleActions" v-if="account.IsActive" />
            
        </div>
        
        <div class="itemdetail" >
            <div class="horizFlex wrap topBorderDiv" v-if="showActions">
                <addfunds :account="account" @refresh="$emit('refresh')" v-if="showAddFundsButton"/>
                <requestrefund :account="account" @refresh="$emit('refresh')" v-if="isAdmin && showRequestRefund" :showButton="trueParam"/>
                <addadmintrans :account="account"  @refresh="$emit('refresh')" v-if="isAdmin" :showButton="trueParam"/>
                <addproducttrans :account="account"  @refresh="$emit('refresh')" v-if="isAdmin && showAddProduct" :showButton="trueParam"/>
                <adddocumenttrans :account="account"  @refresh="$emit('refresh')" v-if="isAdmin && showAddDocument" :showButton="trueParam"/>
                <grantaccess :account="account"  @refresh="$emit('refresh')" v-if="isAdmin" showButton="true"/>
                <newmealplan :account="account"  @refresh="$emit('refresh')" buttonclass="button buttonmeal" v-if="isAdmin && isMealPlan" />
                <updateaccounttype :account="account"  @refresh="$emit('refresh')" buttonclass="button buttonmeal" v-if="isAdmin && isMealPlan" />
                <reprocesstickets  :account="account"  @refresh="$emit('refresh')" buttonclass="button buttonmeal" v-if="isAdmin && isMealPlan" />
                <confirmwithreason :showButton="trueParam" body="Do you want to deactivate this account?" title="Deactivate Account" v-model="deactivateReason" @yes="DeactivateAccount()" v-if="isAdmin"/>
            </div>
            <div class="vertFlexLeft topBorderDiv" >
                <div :class="mainedgeClass">
                    <div class="leftColumn">
                        <div class="horizFlex" v-if="showInitialBalance">
                            <label class="label right10"> {{formatInitBalance(account.initialBalance)}}</label>
                            <updateinitialbalance :account="account" @refresh="$emit('refresh')" v-if="isAdmin && account.IsActive"/>               
                        </div>
                        <div class="horizFlex" v-if="showInitialDue">
                            <label class="label right10"> {{formatInitDue(account.initialAmountDue)}}</label>
                            <updateinitial-due :account="account" @refresh="$emit('refresh')" v-if="isAdmin && account.IsActive"/>               
                        </div>
                        <div class="horizFlex" v-if="showAllowXOverBalanceOverride">
                            <label class="label right10"> {{formatBalanceOverride(account.allowXOverBalanceOverride)}}</label>
                            <updateaoverbalanceoverride :account="account" @refresh="$emit('refresh')" v-if=" account.IsActive"/>               
                        </div>
                        <div class="horizFlex" v-if="showGuestAlerts">
                            <label :class="guestalertclass(account.guestAlert)"> {{formatGuestAlert(account.guestAlert)}}</label>
                            <updateguestalert :guestAlert="account.guestAlert" :personId="account.personId" @refresh="$emit('refresh')" v-if=" account.IsActive"/>               
                        </div>
                        <div class="horizFlex" v-if="showRefill">
                            <label class="label right10"> {{formatRefillBelow(account.refillWhenBelowXDollars)}} {{ formatRefillWith(account.refillWithXDollars)}}</label>
                            <updaterefill :account="account" @refresh="$emit('refresh')" v-if="account.IsActive"/> 
                        
                        </div>
                        <div class="horizFlex">
                            <label class="label right10" >
                                {{'Preferred Payment Method: ' + account.preferredWalletTokenName}}
                            </label>
                            <updatepreferredtoken :account="account" @refresh="$emit('refresh')" v-if="account.IsActive"/>
                        </div>
                    </div>
                    <div class="centerColumn" v-if="account.statusString!=null">
                        <label class="wrappedtext">{{account.statusString}}</label>
                    </div>
                    <div class="rightColumn margin20">
                        <label class="wrappedtext">{{account.checklistString}}</label>
                    </div>
                </div>
                
                <div class="topBorderDiv">
                    <div class="messagetext horizFlex">
                        <em class="note right10">{{account.message}}</em>
                        <accountnote :accountId="account.ID" @refresh="$emit('refresh')"/>
                    </div>
                    <div class="buttonstretch topBorderDiv padtop" v-if="isAdmin">
                        
                        <label  class="mediumtext">Item Type: </label>
                        <div class="horizFlex" v-if="showDocuments">
                            <input id="doc" class="entryCheckbox" type="checkbox" v-model="filterDocument"  @change="filterDoc()" >
                            <label for="doc" class="mediumtext">Documents</label>
                        </div>
                        <div class="horizFlex" v-if="showAttendance">
                            <input id="att" class="entryCheckbox" type="checkbox" v-model="filterAttendance"  @change="filterAtt()" >
                            <label for="att" class="mediumtext">Attendance</label>
                        </div>
                        <div class="horizFlex" v-if="showAcademics">
                            <input id="aca" class="entryCheckbox" type="checkbox" v-model="filterAcademics"  @change="filterAca()" >
                            <label for="aca" class="mediumtext">Academics</label>
                        </div>
                        <div class="horizFlex" v-if="showMemos">
                            <input id="aca" class="entryCheckbox" type="checkbox" v-model="filterMemos"  @change="filterMem()" >
                            <label for="aca" class="mediumtext">Memos</label>
                        </div>
                        <div class="horizFlex" v-if="showTransactions">
                            <input id="tra" class="entryCheckbox" type="checkbox" v-model="filterTransaction"  @change="filterTra()" >
                            <label for="tra" class="mediumtext">Financial</label>
                        </div>
               
                        <div class="horizFlex">
                            <linkbutton class="detailbutton" toplabel="Select All Types"  @click="filterA()" v-if="showDetailButton"/>
                            <linkbutton class="detailbutton" :toplabel="detailLabel"  @click="toggleDetail" v-if="showDetailButton"/>
                        </div>
                        
                    </div>
                    <div class="rightJustified">
                        
                    </div>
                    <div class="buttonstretch">
                        <div class="filterDateDiv " v-if="isAdmin">
                            <div class="horizFlex ">
                                <input class="entryCheckbox margintop" type="checkbox" v-model="filterByDate" @change="filterItems()">
                                <label for="" class="mediumtext margintop">Filter by Date</label>
                                <input class="datetext" type="date" :disabled="!filterByDate" v-model="filterByStartDate" @change="filterItems()"/>
                            </div>
                            <div class="horizFlex">
                                <label for="" class="mediumtext margintop">to </label>
                                <input class="datetext" type="date" :disabled="!filterByDate" v-model="filterByEndDate" @change="filterItems()"/>
                            </div>
                            <div class="horizFlex ">
                                <input class="entryCheckbox margintop" type="checkbox" v-model="showInactiveTransactions" >
                                <label for="" class="mediumtext margintop">Show Voided/Failed Transactions</label>
                            </div>
                        </div>
                  
                        
                    </div>
                   
                </div>
            </div>
            
            
            <div v-if="showDetail">
                <!-- <div class="horizFlex ">
                    <input class="entryCheckbox" type="checkbox" v-model="showInactiveTransactions" >
                    <label for="" class="mediumtext">Show Voided/Failed Transactions</label>
                </div> -->
                <div class="transdiv">
                    <div v-for="transaction in filterTransactions(account.transactionList)" :key="transaction.ID" :value="transaction.ID" >
                        <transactionview :transaction="transaction" @refresh="$emit('refresh')" />
                    </div>
                </div>
            </div>
            
        </div> 
         <!-- ACTIONS -->
         <imageeditor ref="imgedit" :img="selectedImageURL" @save="saveedits"/>
        <imageall ref="imgall" :img="selectedImageURL" @saveimg="saveedits" :allowFileSelect="paramTrue" :showImage="paramFalse"/>
       
    </div>
    
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import datamethods from '../../datamethods.js'
import format from '../../format.js'
import linkbutton from '../controls/linkbutton.vue'
import smallbutton from '../controls/button.vue'
import transactionview from './accounttransaction.vue'
import updatestudent from '../actions/updatestudent.vue'
import accountnote from '../actions/accountnote.vue'
import updateinitialbalance from '../actions/updateinitialbalance.vue'
import updateaoverbalanceoverride from '../actions/updateoverbalanceoverride.vue'
import updateinitialDue from '../actions/updateInitialDue.vue'
import updaterefill from '../actions/updaterefill.vue'
import updatepreferredtoken from '../actions/updatepreferredtoken.vue'
import addfunds from '../actions/addfunds.vue'
import requestrefund from '../actions/requestrefund.vue'
import addadmintrans from '../actions/addadmintrans.vue'
import grantaccess from '../actions/grantaccess.vue'
import newmealplan from '../actions/newmealplan.vue'
import updateaccounttype from '../actions/updateaccounttype.vue'
import reprocesstickets from '../actions/reprocesstickets.vue'
import adddocumenttrans from '../actions/adddoctrans.vue'
import confirmwithreason from '../actions/confirmwithreason.vue'
import addproducttrans from '../actions/addproducttrans.vue'
import updateTuitionType from '../actions/updateTuitionType.vue'
import updatereferredby from '../actions/updateReferredBy.vue'
import updatemealplan from '../actions/updateMealPlan.vue'
import imageeditor from '../controls/ImageEditor.vue'
import imageall from '../controls/ImageAll.vue'
import updateguestalert from '../actions/updateguestalert.vue'
export default {
    name:"accountview",
    mixins:[format,datamethods],
    components:{
        smallbutton,
        linkbutton,
        transactionview,
        updatestudent,
        accountnote,
        updateinitialbalance,
        updateinitialDue,
        updaterefill,
        updatepreferredtoken,
        addfunds,
        requestrefund,
        addadmintrans,
        grantaccess,
        newmealplan,
        updateaccounttype,
        reprocesstickets,
        adddocumenttrans,
        confirmwithreason,
        addproducttrans,
        updateTuitionType,
        updatereferredby,
        updatemealplan,
        updateaoverbalanceoverride,
        imageeditor,
        imageall,
        updateguestalert
    },
    props:{
        account:{
            type:Object,
            required:true
        },
        
    },
  
    data() {
        return {
            trueParam:true,
            showDetail:false,
            showDetailButton:true,
            showInactiveTransactions:false,
            isMealPlan:window.ACCOUNT_OPTION=="True",
            showActions:true,
            showMealActions:false,
            deactivateReason:'',

            //config settingws
            showDocuments:window.SHOW_DOCUMENTS,
            showTransactions:window.SHOW_TRANSACTIONS,
            showAttendance:window.SHOW_ATTENDANCE,
            showAcademics:window.SHOW_ACADEMICS,
            showMemos:window.SHOW_MEMOS,
            showAddDocument:window.SHOW_ADD_DOCUMENT,
            showRefill:window.SHOW_REFILL,
            showAddProduct:window.SHOW_ADD_PRODUCT_TRANS,
            showInitialBalance:window.SHOW_INITIAL_BALANCE,
            showInitialDue:window.SHOW_INITIAL_DUE,
            showAccountBalance:window.SHOW_ACCOUNT_BALANCE,

            filterDocument:true,
            filterAcademics:true,
            filterMemos:true,
            filterAttendance:true,
            filterTransaction:true,
            filterAll:true,
            filterByDate:false,
            filterByStartDate:'',
            filterByEndDate:'',

            //image
            selectedImageURL:null,
            personImageEnlarged:false,
            selectedImageType:'',
            paramTrue:true,
            paramFalse:false, 
        };
    },
    mounted(){

       /*  if(this.alwaysopen=='true'){
            this.showDetailButton=false
            this.showDetail=true} */
        if(!this.isAdmin){this.showDetail=true}
        if(!this.isAdmin){this.showActions=true}
        this.filterByStartDate=this.$options.filters.formatDateForInputToday();
        this.filterByEndDate=this.$options.filters.formatDateForInputToday();
    },
    computed:{
        
        getImage(){
            console.log(this.personImageBasePath + this.account.photoFileName)
            if(this.account.photoFileName==null){return null}
            
            return this.personImageBasePath + this.account.photoFileName;
        },
        showAccountImage(){
            
            if(window.SHOW_ACCOUNT_IMAGE==undefined){return false}
            else{return window.SHOW_ACCOUNT_IMAGE}
        },
        showAddFundsButton(){
            return (this.isMyWallet || !this.disableAddFunds) && this.showAddFunds
        },
        showAccountType(){
            if(window.SHOW_ACCOUNT_TYPE==undefined){return false}
            else{return window.SHOW_ACCOUNT_TYPE}
        },
        showRequestRefund(){
            if(window.SHOW_REQUEST_REFUND==undefined){return false}
            else{return window.SHOW_REQUEST_REFUND}
        },
        showTuitionPaymentType(){
            if(window.SHOW_TUITION_PAYMENT_TYPE==undefined){return false}
            else{return window.SHOW_TUITION_PAYMENT_TYPE}
        },
        showReferredBy(){
            if(window.SHOW_REFFERRED_BY==undefined){return false}
            else{return window.SHOW_REFFERRED_BY}
        },
        showIdNumber(){
            if(window.SHOW_ID_NUMBER==undefined){return false}
            else{return window.SHOW_ID_NUMBER}
        },


        showMealPlan(){
            if(window.SHOW_MEALPLAN==undefined){return false}
            else{return window.SHOW_MEALPLAN}
        },
        showAllowXOverBalanceOverride(){
            if(window.SHOW_ALLOWXOVERBALANCE_OVERRIDE==undefined){return false}
            else{return window.SHOW_ALLOWXOVERBALANCE_OVERRIDE}
        },
        showGuestAlerts(){
            if(window.SHOW_GUEST_ALERTS==undefined){return false}
            else{return window.SHOW_GUEST_ALERTS}
        },
        referredByLabel(){

            if(window.REFERREDBY_LABEL==undefined){return "Referred By: "}
            else{return window.REFERREDBY_LABEL + ': '}
   
        },
        dueText(){
            if(window.DUE_TEXT==undefined){return "Due"}
            else{return window.DUE_TEXT}
            
        },
        idLabel(){
            var l = "Permit #"
            if(window.ID_LABEL_OVERRIDE!=undefined){
                if(window.ID_LABEL_OVERRIDE!=''){
                    l=window.ID_LABEL_OVERRIDE
                }
            }
            return l
        },
        mainedgeClass(){
            var c ="mainedge"
            if(this.account.IsActive){c = c + " active"}
            else{c=c+" inactive"}
            return c
        },
        dueLabelClass(){
            if(this.account.amountDue>0){
                return "labelRed"
            }else{return "label"}
        },
        accountHasDueDate(){
            if(this.account.dueDate.includes('0001-01')){return false}
            return true
        },
        detailLabel(){
            return this.showDetail ? "Hide Items" : "View Items"
        },
        actionLabel(){
            return this.showActions ? "Hide Actions" : "Show Actions"
        },
        mealActionLabel(){
            return this.showMealActions ? "Hide Meal Actions" : "Show Meal Actions"
        },
        buildResultName(){
            var name = this.account.Name
            if (!this.account.IsActive){
                name = name + ' INACTIVE'
            }
            return name
        },
         ...mapFields([
            'loggedInUser.isAdmin',
            'pleaseWait',
            'pleaseWaitText',
            'responseMessage',
            'disableAddFunds',
            'showAddFunds',
            'loggedInUser.walletId',
            'currentWallet',
            'personImageBasePath'
            

        ]),
        isMyWallet(){
            return this.walletId==this.currentWallet.ID
        },
        detailbuttonDoc(){
            return this.filterDocument ? "detailbutton detailbuttonselected" : "detailbutton"
        },
        detailbuttonAtt(){
            return this.filterAttendance ? "detailbutton detailbuttonselected" : "detailbutton"
        },
        detailbuttonAca(){
            return this.filterAcademics ? "detailbutton detailbuttonselected" : "detailbutton"
        },
        detailbuttonMem(){
            return this.filterMemos ? "detailbutton detailbuttonselected" : "detailbutton"
        },
        detailbuttonTra(){
            return this.filterTransaction ? "detailbutton detailbuttonselected" : "detailbutton"
        },
        detailbuttonA(){
            return this.filterAll ? "detailbutton detailbuttonselected" : "detailbutton"
        },
    },
    methods:{
        obscuredFID(account){
            return 'FID: '  + this.$options.filters.obscureFID(account.fId)
        },
        tuitionPmtType(account){
            var sponsor = 'Sponsor: '  + this.$options.filters.handleNull(account.tuitionPaymentTypeName)
            if(account.sponsor!=null){
                sponsor = sponsor + " (" + account.sponsor + ")"
            }
            return sponsor
        },
        referred(account){
            return this.referredByLabel  + this.$options.filters.handleNull(account.referredBy)
        },
        mealplan(account){
            return "Meal Plan " + this.$options.filters.handleNull(account.mealPlan)
        },
        filterDoc(){
            //this.filterDocument=!this.filterDocument
            this.filterAll=false
            this.filterItems();
        },
        filterAtt(){
            //this.filterAttendance=!this.filterAttendance
            this.filterAll=false
            this.filterItems();
        },
        filterAca(){
            //this.filterAcademics=!this.filterAcademics
            this.filterAll=false
            this.filterItems();
        },
        filterMem(){
            //this.filterMemos=!this.filterMemos
            this.filterAll=false
            this.filterItems();
        },
        filterTra(){
           // this.filterTransaction=!this.filterTransaction
            this.filterAll=false
            this.filterItems();
        },
        filterA(){
            this.filterAll=true
            this.filterDocument=true
            this.filterAcademics=true
            this.filterAttendance=true
            this.filterMemos=true
            this.filterTransaction=true
            this.filterItems();
        },
        filterItems(){
 
            var filter={
                walletId:this.currentWallet.ID,
                accountId:this.account.ID,
                restrictToIsActive:0,
                filterByStartDate:null,
                filterByEndDate:null,
                filterByAccountTransactionTypeID:0,
                restrictToAddBalance:0,
                restrictToSubtractBalance:0,
                filterDocument:this.filterDocument,
                filterAttendance:this.filterAttendance,
                filterAcademics:this.filterAcademics,
                filterMemos:this.filterMemos,
                filterTransaction:this.filterTransaction,
                filterAll:this.filterAll
            }
            if(this.filterByDate){
                filter.filterByStartDate=this.filterByStartDate
                filter.filterByEndDate=this.filterByEndDate
            }
            /* if(this.filterTransaction){
                filter.restrictToAddBalance=1
                filter.restrictToSubtractBalance=1
            } */
            this.showDetail=true
            this.$emit("gettrans",filter)
        },
        toggleDetail(){
            this.showDetail=!this.showDetail;
        },
        toggleActions(){
            this.showActions=!this.showActions;
        },
        toggleMealActions(){
            this.showMealActions=!this.showMealActions;
        },
        // FORMATTING
        formatBalance(bal){
            bal = this.$options.filters.toCurrency(bal)
            return 'Balance: ' + bal
        },
        formatInitBalance(bal){
            bal = this.$options.filters.toCurrency(bal)
            return 'Initial Balance: ' + bal
        },
        formatInitDue(bal){
            bal = this.$options.filters.toCurrency(bal)
            return 'Total Amount Due: ' + bal
        },
        formatBalanceOverride(bal){
            if(bal<0){
                    return "Charging Limit: None"
                }else{
                    var old = this.$options.filters.toCurrency(bal)
                    return "Charging Limit: " + old
                }

        },
        formatGuestAlert(note){
            if(note==null){return "No Alerts"}
            if(note.name=null){return "No Alerts"}
            return "Alert: " + note.note
        },
        guestalertclass(note){
            if(note==null){return 'label right10'}
            if(note.note==null){return 'label right10'}
            return 'label right10 labelRed'
        },
        formatRefillBelow(bal){
            bal = this.$options.filters.toCurrency(bal)
            return 'Refill Below: ' + bal
        },
        formatRefillWith(bal){
            bal = this.$options.filters.toCurrency(bal)
            return ' With: ' + bal
        },
        formatAmountString(amt, fee,type){
            amt =this.$options.filters.toCurrency(amt)
            fee =this.$options.filters.toCurrency(fee)
            return type + ' Amount: ' + amt 
        },
        formatFeeAmountString(amt){
            amt =this.$options.filters.toCurrency(amt)
        
            return ' Fee: ' + amt
        },
        filterTransactions(transactions){
           // this.filterTransaction=!this.filterTransaction
            if(this.showInactiveTransactions){
                return transactions;
            }else{
                let temp = transactions;
                if(temp!=undefined){
                    temp = temp.filter((item)=>{
                        return (item.IsActive)
                    })
                }
                
                return temp
            } 
        },
        async DeactivateAccount(){
            let req = {
                id:this.account.ID,
                reason:this.deactivateReason,
                personId:this.account.personId
            }
            var response = await this.callAPI(req,'deactivateaccount')
            if(response.Successful){
                this.showActions=false
                this.$emit('refresh')
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        },
        async ReactivateAccount(){
            let req = {
                id:this.account.ID,
                reason:this.deactivateReason,
                personId:this.account.personId
            }
            var response = await this.callAPI(req,'reactivateaccount')
            if(response.Successful){
                this.$emit('refresh')
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        },
        //image
        recordImageClass(){
            var rclass ="recordImage"
           
            if(this.personImageEnlarged){rclass= "recordImageEnlarged"}
                    

            return rclass

        },
        toggleImageSize(image,img){
            console.log('el ' + img)
            var enlarged = false
            
            this.personImageEnlarged = !this.personImageEnlarged
            enlarged=true
                  
            var item = 'person'
            if(enlarged){
                this.selectImage(image,img,item)
            }
        },
        selectImage(image,img,item){
            //this.selectedRecord=item
            this.selectedImage=image
            //this.selectedImageTypeID=image.badgeImageTypeId
            this.selectedImageURL= img.src
            this.selectedImageType=item
            //this.selectedImageBadgeID = item.badgeId
        },
        saveedits(file, url){
            console.log('saveedits')
            this.selectedImageURL=url
        
            this.storeImage("", file, file.name)
        },
       async storeImage(filename,file, name){
            var bId=this.selectedImageBadgeID
            var postParams = new FormData();

            postParams.append(filename,file,name);
            
            postParams.append('personId',this.account.personId);
            //postParams.append('badgeId',this.studentcurrentPerson.badgeId);
            postParams.append('type',this.selectedImageType);

            var response = await this.callAPI(postParams,'saveimage')
            //handle response
            if(response.Successful){
                this.$emit('refresh')
            }else{
                this.responseMessagemessage = response.Message
            }
        },
    }
}
</script>

<style scoped>
/* full screen */
@media screen and (min-width: 812px) {
.mainedge{
    display:grid;
   grid-template-columns:auto 1fr auto 1fr;
    grid-template-rows:1fr auto ;
    grid-template-areas: 
    "photo left space right"
    "detail detail detail detail";
}
.filterDateDiv{
    display:flex;
  flex-direction: row;
  margin:0px;
}
}
/* phone */
@media screen and (max-width: 812px) {
.mainedge{
    display:grid;
    grid-template-columns:1fr auto 1fr;
    grid-template-rows:1fr auto auto ;
    grid-template-areas: 
    "left blank right"
    "space blank blank2"
    "detail detail detail";
}
.filterDateDiv{
display:flex;
  flex-direction: column;
  margin:0px;
}
}

.topBorderDiv{
    border-top:1px solid darkgray;
    padding-top:5px;
}
.mainedge{

    background-color: var(--frame-color);
    border-radius:5px;
    margin:10px;
    padding:10px;
    /* display:grid; */
    /* grid-template-columns:1fr auto 1fr;
    grid-template-rows:1fr auto ;
    grid-template-areas: 
    "left space right"
    "detail detail detail"; */
}
.active{
    background-color: var(--frame-color);
}
.inactive{
    background-color:var(--inactive-color);
}
.leftColumn{
    grid-area:left;
    display:flex;
    flex-direction: column;
    text-align: left;
}
.rightColumn{
    grid-area:right;
    display:flex;
    flex-direction: column;
    margin-left:0px;
    text-align:right;
    
}
.centerColumn{
    grid-area:space;
    display:flex;
    flex-direction: column;
    margin-left:0px;
    text-align:center;
}
.centered{
    grid-area:space;
    display:flex;
    flex-direction: column-reverse;
    
}
.itemdetail{
    grid-area:detail;
    /* border-top:1px solid darkgray; */
    margin-top:5px;
    padding-top:5px;
}
.labelBold{
  font-size:large;
  font-weight: bold;
}
.label{
  
}
.labelRed{
    color:red;
}
.detailbutton{
    width:150px;
    text-align: right;
    border:none;
}
.detailbuttonselected{
    border:2px solid var(--button-color);

}
.rightJustified{
    text-align: right;
}
.buttonstretch{
    display:flex;
    padding:0px;
    flex-direction: row;
    flex-wrap:wrap;
    margin:0px 0px 0px 0px ;
    justify-content:space-between;
}
.wrappedtext{
    white-space: pre;
    text-align:left;
}
.margin20{
    margin-left:40px;
}
.wrap{
    flex-wrap:wrap;
}
.checkimg{
    height:15px;
}
.entryCheckbox + label{
    font-weight: normal;
}
.entryCheckbox:checked + label{
    font-weight:bold;
}
.datetext{
    margin:0px 10px 0px 10px;
    font-style:inherit;
    font-size:large;
    
}
.padtop{
    padding-top:10px;
}
.margintop{
    margin-top:5px;
}
.imageclass{
    margin:0px 10px 0px 0px;
}
.personPhoto{
    display:flex;
    flex-direction:column;
}
/* image */
.recordImage{
  width:70px;
  margin:0px 10px 0px 0px;
  transition: height 1s;
}
.recordImageEnlarged{
  width:100px;
  margin:0px 10px 0px 0px;
}
/* .recordImage:hover{
  width:100px;
} */
</style>
