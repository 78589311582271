<template>
<div>
    <smallbutton toplabel="Add Account" @click="AddStudent()"/>
    <!--  MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form id="studentform" ref="studentform" @submit.prevent>
                <label class="titlelabel">Add Account</label>
                <formentry :label="studentfirstNameLabel" isrequired='True' v-model="NewPerson.FirstName" />
                <formentry :label="studentlastNameLabel" isrequired='True' v-model="NewPerson.LastName"  />
                <formentry :label="studentIDLabel" :isrequired="requireStudentID" v-model="NewPerson.StudentID"  />
                 <formentry :label="fidLabel" :isrequired='requireFID'    v-model="NewPerson.fId" v-if="showFID" :placeholder="FIDPlaceholder"/>
                <formentry :label="studentPreferredLabel"  v-model="NewPerson.PreferredFirstName" />

                <div class="entry">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Role</label>
                    </div>
                    <select name="" id="" class='entrySelect' v-model="personRoleId" required v-if='allowSelectPersonRole'>
                        <option v-for="role in personRoleList" :key="role.id" :value="role.id" >
                            {{role.name}}
                        </option>
                    </select>
                </div>
                <smallbutton  @click="saveNewStudent()" toplabel="Save" />
                <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Cancel"/>
                <div>
                    <label class="responsemessage">{{responseMessage}}</label>
               </div>
            </form>
            
        </template>
      </actionmodal>
    </div>
</template>

<script>
import datamethods from '../../datamethods.js'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import actionmodal from './actionmodal.vue'
import formentry from '../controls/entry.vue'
export default {
    name:'newstudent',
    components:{
        smallbutton,
        actionmodal,
        formentry
    },
    props:{
        walletId:{
            type:Number,
            required:true
        },
        
    },
    mixins:[datamethods,format],
    data(){
        return{
            
            responseMessage:'',
            pleaseWait:false,

            requireStudentID:window.REQUIRESTUDENTID.toString(),

            NewPerson:{},
            studentfirstNameLabel:'First Name',
            studentlastNameLabel:'Last Name',
            studentPreferredLabel:'Preferred Name (Nickname)',

            fidLabel:'SSN',

            personRoleList:[],
            personRoleId:0
        }
    },
    computed:{
        allowSelectPersonRole(){
            if(window.ALLOW_SELECT_PERSON_ROLE==undefined){return false}
            else{return window.ALLOW_SELECT_PERSON_ROLE}
        },
        studentIDLabel(){
            if(this.requireStudentID){return 'School ID'}else{return 'School ID (optional)'}
        },
        requireFID(){
            if(window.REQUIRE_SSN==undefined){return false}
            else{return window.REQUIRE_SSN=="True"}
        },
        showFID(){
            if(window.SHOW_SSN==undefined){return false}
            else{return window.SHOW_SSN=="True"}
        },
        FIDPlaceholder(){
            var placeholder=''
            if(window.SSN_LENGTH==undefined){placeholder='last 4 digits'}
            else if(window.SSN_LENGTH==4){placeholder='last 4 digits'}
            else{placeholder=window.SSN_LENGTH + ' digits'}
            return placeholder
        },
        FIDLength(){
            var l=4
            if(window.SSN_LENGTH!=undefined){l=window.SSN_LENGTH}
            return l
        }
    },
    methods:{
        isOKFID(fid){
            if(fid==null){return true}
            fid = fid.replaceAll('-','').replaceAll('.','').replaceAll(' ','')
            console.log(fid.length)
            return fid.length==this.FIDLength
        },
        beforeAPI(){
            this.pleaseWait=true
            this.responseMessage=''
        },
        AddStudent(){
             this.NewPerson={}
             this.getRoles()
            this.$refs.mymodal.openModal()
        },
       async saveNewStudent(){
            if(this.$refs.studentform.checkValidity()){
                
                if(!this.isOKFID(this.NewPerson.fId)){
                    if(this.FIDLength==4){
                        this.responseMessage="Please enter the last 4 digits of your SSN"
                    }else{
                        this.responseMessage="Please enter a " + this.FIDLength + ' digit SSN'
                    }
                
                return
                }

                this.beforeAPI();
                this.NewPerson.FirstName=this.capitalize(this.NewPerson.FirstName)
                this.NewPerson.LastName=this.capitalize(this.NewPerson.LastName)
                let req = {
                    person:this.NewPerson,
                    walletId:this.walletId,
                    accountTag:window.ACCOUNT_TAG,
                    personRoleId:this.personRoleId
                }

                var response = await this.callAPI(req,'addpersonandaccount')
 
                if(response.Successful){
                    this.$refs.mymodal.closeModal()
                    this.$emit('refresh')
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false;
            }
        },
        async getRoles(){
            let req = {
                locationId:-1
            }
            this.beforeAPI();
            var response = await this.callAPI(req,'rolelist')

            //handle response
            if(response.Successful){
            this.personRoleList = response.PersonRoleList
    
            if(this.personRoleList.length>0){
               //find student and pick it as default
                var studentId=-1
                this.personRoleList.forEach(myFunction2)

                function myFunction2(item, index, arr) {
                    let acct=arr[index]
                    if(acct.name=='Student'){
                        studentId=acct.id
                    }
                }
                this.personRoleId=studentId 
            }
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
    }
}
</script>

<style>
.entryCheckLabel{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
}
.entryCheckLabelbold{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
    font-weight:bold;
}
</style>





























